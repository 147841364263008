import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Slider from "react-slick";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  contentfulRichTextPropType,
  gatsbyImageDataPropType,
} from "~/propTypes";
import AnimatedBlock from "~/components/AnimatedBlock";
import FeaturesList from "~/components/FeaturesList";
import Layout from "~/components/layout/Layout";
import PageSectionList from "~/components/PageSectionList";
import YoutubeEmbed from "~/components/YoutubeEmbed";
import ContentfulRichText from "~/components/ContentfulRichText";
import "~/styles/slick.scss";
import "./Project.scss";

function Project({ data: { project, template } }) {
  return (
    <Layout>
      <section>
        <div className="container">
          <div className="row">
            <div className="a-project-head">
              <AnimatedBlock order={1}>
                <span className="a-project-location">{project.subHeading}</span>
                <h1>{project.heading}</h1>
              </AnimatedBlock>
              <AnimatedBlock order={3}>
                <div className="line-yellow small-line-margin" />
              </AnimatedBlock>
              {project.features ? (
                <AnimatedBlock order={5}>
                  <FeaturesList features={project.features} />
                </AnimatedBlock>
              ) : null}
            </div>
          </div>
        </div>
      </section>

      <section className="bg-blue-light section-pad-sm no-pad-top">
        <div className="container">
          <div className="row">
            <div className="a-project">
              <Slider
                dots
                arrows={false}
                speed={1000}
                infinite
                slidesToShow={1}
                slidesToScroll={1}
                autoplay
                autoplaySpeed={5000}
                cssEase="linear"
              >
                {project.video ? (
                  <YoutubeEmbed
                    sourceUrl={project.video}
                    title={project.heading}
                  />
                ) : (
                  project.images?.map((image) => (
                    <div key={image.id}>
                      <div className="a-project-img">
                        <GatsbyImage
                          image={getImage(image.gatsbyImageData)}
                          alt=""
                          className="img-shadow-lg"
                        />
                      </div>
                    </div>
                  ))
                )}
              </Slider>
              <div className="a-project-content">
                <ContentfulRichText content={project.content} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {template.sections ? <PageSectionList data={template.sections} /> : null}
    </Layout>
  );
}

Project.propTypes = {
  data: PropTypes.shape({
    project: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      heading: PropTypes.string.isRequired,
      subHeading: PropTypes.string.isRequired,
      features: PropTypes.arrayOf(PropTypes.string),
      video: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          gatsbyImageData: gatsbyImageDataPropType.isRequired,
        }).isRequired
      ),
      content: contentfulRichTextPropType.isRequired,
    }).isRequired,
    template: PropTypes.shape({
      sections: PageSectionList.propTypes.data,
    }),
  }).isRequired,
};

const query = graphql`
  query ProjectQuery($id: String!) {
    project: contentfulProject(id: { eq: $id }) {
      metadata {
        ...Seo_data
      }
      content {
        raw
      }
      heading
      slug
      subHeading
      features
      video
      images {
        id
        gatsbyImageData(
          placeholder: NONE
          backgroundColor: "white"
          layout: FULL_WIDTH
          aspectRatio: 1.7777778
          quality: 100
        )
      }
    }
    template: contentfulPageTemplate(internalId: { eq: "Project" }) {
      sections {
        ...PageSectionList_data
      }
    }
  }
`;

export { query };
export default Project;
